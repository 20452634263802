import { navigate, Link } from "gatsby"
import _ from "lodash"
import React, { useEffect } from "react"
import tw from "twin.macro"
import useTealiumView from "../../../../hooks/Tealium/useTealiumView"
import { ButtonLink } from "../../../atoms/Button"
import Icon from "../../../atoms/Icon"
import { SearchBar } from "../../SearchBar"
import { ThreeArrowAnimation } from "../../ThreeArrowAnimation"

const NotFoundPageContentEs = () => {
  const pageLinks = [
    { label: "Conoce nuestros<br>vehículos", link: "/es" },
    { label: "Buscar un<br>concesionario", link: "/es/dealers" },
    { label: "Ver todas<br>las ofertas", link: "/es/offers" },
  ]

  // Tealium
  const { handleTealView } = useTealiumView()
  useEffect(() => {
    handleTealView({
      page_name: "error",
      page_type: "error",
      tealium_event: "error_page",
      error_message: `Uh oh. Dead end. - We couldn't find what you are looking for, but the journey doesn't end here. Let's get you back on your way. Requested URL:${location} `,
    })
  }, [])
  return (
    <div>
      <section
        css={[
          tw`min-h-[calc(100vh - 118px)] bg-cover bg-no-repeat px-4 pt-8 md:bg-right-bottom`,
          `background-position: 90% bottom;`,
          `background-image: url(${process.env.GATSBY_ROOT_URL}404_hero.jpg)`,
        ]}
      >
        <div css={tw`max-w-[1440px] mx-auto`}>
          <button
            css={[tw`flex items-center text-xs text-red-400 md:ml-10 lg:ml-24`]}
            onClick={() => navigate(-1)}
          >
            <Icon.Chevron
              color="red-400"
              direction="left"
              css={[tw`mr-1 w-2`]}
            />
            Regresar
          </button>
        </div>
        <div css={tw`max-w-xl mx-auto text-center pt-20`}>
          <p css={tw`text-red-400 font-semibold mb-2`}>ERROR 404</p>
          <h1 css={[tw`text-2xl font-light mb-6 font-book`, tw`lg:(text-4xl)`]}>
            ¡Oh, no! Camino sin salida.
          </h1>
          <p css={tw`mb-6`}>
            No pudimos encontrar lo que buscas, pero lo bueno es que aquí no
            termina la travesía. Retoma tu camino.
          </p>
          <div css={tw`grid grid-cols-2 items-center gap-2 md:gap-6`}>
            <ButtonLink to="/" primary css={tw`w-auto`}>
              Página principal
            </ButtonLink>
            <SearchBar mobileView={false} secondarySearchBar={true} />
          </div>
        </div>
      </section>
      <section
        css={tw`grid grid-cols-3 justify-between max-w-desktop py-12 mx-auto`}
      >
        {pageLinks.map(({ label, link }, i) => (
          <div
            css={[i !== pageLinks.length - 1 && tw`border-r-2 border-gray-400`]}
          >
            <Link
              className="group"
              to={link}
              css={[
                tw`p-4 font-semibold text-xl text-center flex flex-col content-center justify-center sm:flex-row md:text-2xl`,
                tw`focus-visible:(outline-gray)`,
              ]}
            >
              <span dangerouslySetInnerHTML={{ __html: label }} />
              <span
                css={tw`relative w-10 h-5 -ml-3 sm:mr-0 sm:ml-2 sm:w-auto `}
              >
                <ThreeArrowAnimation large />
              </span>
            </Link>
          </div>
        ))}
      </section>
    </div>
  )
}

export default NotFoundPageContentEs
